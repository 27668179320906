<template>
    <div class="search-filters" :class="CSSClasses">
        <div v-show="isPhone" class="mobile-filters-sticky">
            <Typography variant="body-display-large" class="mobile-filter-toggler-title">{{ title }}</Typography>

            <Button @click="() => toggleFilters()" variant="tertiary" class="mobile-filters-toggle">
                <template #icon>
                    <Icon name="filters" />
                </template>
                <template #accessory>
                    <div v-if="filtersCount" class="mobile-filters-count">
                        {{ filtersCount }}
                    </div>
                </template>
            </Button>
        </div>

        <Transition name="vue-fade">
            <div v-show="isPhone ? areMobileFiltersToggled : true" class="search-filters-wrapper">
                <div class="mobile-filter-heading">
                    <Typography class="mobile-title" as="h5" variant="h5" transform="capitalize"
                        >{{ $t('filters') }}
                    </Typography>
                    <Button @click="() => toggleFilters()" variant="tertiary" class="mobile-close-button">
                        <template #icon>
                            <Icon name="close" class="mobile-close-icon" />
                        </template>
                    </Button>
                </div>

                <div class="filters">
                    <template v-for="(value, key) in filters">
                        <IndexFilterMenu
                            v-if="renderedFilters.includes(key) && value"
                            :title="camelCaseToTitleCase($t(key, 1, { locale: pageLanguage }))"
                            :key="key"
                            :items="value"
                            class="filter"
                            :pageLanguage="pageLanguage"
                        >
                        </IndexFilterMenu>
                    </template>
                </div>

                <div class="chips-controls-wrapper">
                    <div class="filter-chip-row" v-if="flattenedFilters.length">
                        <div class="filter-chips">
                            <div
                                v-for="filter in flattenedFilters"
                                :key="filter?.id ?? filter?.title"
                                class="chip-and-sub"
                            >
                                <template v-if="defaultFilters && filter.default === true">
                                    <Chip theme="outline" type="filter" class="chip top-level"
                                        >{{ filter?.title == 'Posts' ? $t('Commentary') : filter?.title }}
                                    </Chip>
                                    <template v-if="filter?.subItems?.filter((item: FilterItem) => item?.isChecked)">
                                        <button
                                            v-for="(subItem, idx) in filter?.subItems?.filter(
                                                (item: FilterItem) => item?.isChecked,
                                            )"
                                            :key="idx"
                                            :class="`subItem subItem-${idx} breadcrumb-pill chip`"
                                        >
                                            {{ subItem?.title }}
                                        </button>
                                    </template>
                                </template>
                                <template v-else>
                                    <Chip
                                        theme="outline"
                                        type="filter"
                                        class="chip top-level"
                                        @click="filter.isChecked = false"
                                        >{{ filter?.title == 'Posts' ? $t('Commentary') : filter?.title }}
                                        <Icon name="close" class="chip-icon" />
                                    </Chip>
                                    <template v-if="filter?.subItems?.filter((item: FilterItem) => item?.isChecked)">
                                        <template
                                            v-for="(subItem, idx) in filter?.subItems?.filter(
                                                (item: FilterItem) => item?.isChecked,
                                            )"
                                        >
                                            <button
                                                v-if="subItem?.id !== defaultFilterID"
                                                :key="idx"
                                                :class="`subItem subItem-${idx} breadcrumb-pill chip`"
                                                @click="clearSingleFilter(filter, subItem)"
                                            >
                                                {{ subItem?.title }}
                                                <Icon name="close" class="chip-icon" />
                                            </button>
                                            <button
                                                v-if="subItem?.id === defaultFilterID"
                                                :key="idx"
                                                :class="`subItem subItem-${idx} breadcrumb-pill chip`"
                                            >
                                                {{ subItem?.title }}
                                            </button>
                                        </template>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <Button v-if="!defaultFilters" @click="clearFilters()" class="chips-clear" variant="tertiary"
                            >Clear All</Button
                        >
                    </div>
                    <div class="mobile-filter-controls">
                        <Button v-if="!defaultFilters" @click="() => clearFilters()" variant="tertiary">Clear</Button>
                        <Button @click="closeFilters" variant="primary">See Results</Button>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import type { SelectedFilters } from '@/composables/useFilterData';

const props = defineProps<{
    title: string;
    hasSearch: boolean;
    enabledFilters: { topics: boolean; regions: boolean; experts: boolean; years: boolean; contentType: boolean };
    pageLanguage: string;
    preselectedFilters?: string[];
}>();

const emit = defineEmits<{
    (e: 'onFilterChanged', filters: SelectedFilters): void;
}>();

const defaultFilters = {};
const defaultFilterID = null;

function preSelectTopics(topics: ReturnType<typeof useFilterData>['data']['value']['topics']) {
    topics.forEach((topic) => {
        if (props.preselectedFilters?.includes(topic.id)) {
            topic.isChecked = true;
            topic.default = true;
        }
    });
}

function preSelectRegions(regions: ReturnType<typeof useFilterData>['data']['value']['regions']) {
    regions.forEach((region) => {
        if (props.preselectedFilters?.includes(region.id)) {
            region.default = true;
            region.isChecked = true;
        }
        region.subItems.forEach((subItem) => {
            if (props.preselectedFilters?.includes(subItem.id)) {
                region.default = true;
                region.isChecked = true;
                subItem.isChecked = true;
                subItem.default = true;
            }
        });
    });
}

const { data: filterData, getSelectedFilters } = useFilterData({ pageLanguage: props.pageLanguage });
const filters = ref<ReturnType<typeof useFilterData>['data']['value']>({ topics: [], regions: [], experts: [], years: [], contentType: [] });
watch([filterData, props.preselectedFilters], () => {
    filters.value = structuredClone(filterData.value);
    preSelectTopics(filters.value.topics);
    preSelectRegions(filters.value.regions);
});

const renderedFilters = (Object.keys(props.enabledFilters) as (keyof typeof props.enabledFilters)[])
    .filter((key) => props.enabledFilters[key]);

const areMobileFiltersToggled = ref(false);

function toggleFilters() {
    areMobileFiltersToggled.value = !areMobileFiltersToggled.value;
}

function closeFilters() {
    areMobileFiltersToggled.value = false;
}

function clearFilters() {
    filters.value = structuredClone(filterData.value);
}

function clearSingleFilter(filter: FilterItem) {
    filter.isChecked = false;
}

const CSSClasses = computed(() => {
    return [props.hasSearch && 'has-search'];
});

const selectedFilters = ref<SelectedFilters>(getSelectedFilters(toRaw(filters.value)));
const flattenedFilters = ref<FilterItem[]>([]);
const filtersCount = ref<number>(0);

watch(filters, (newFilters) => {
    selectedFilters.value = getSelectedFilters(toRaw(newFilters));
    filtersCount.value = flattenedFilters.value.filter((filter) => filter.isChecked).length;

    const flattened: FilterItem[] = [...newFilters.topics, ...newFilters.experts, ...newFilters.years, ...newFilters.contentType].filter(
        (filter) => filter.isChecked,
    );

    newFilters.regions.forEach((region) => {
        if (region.isChecked) flattened.push(region);
        if (!region.isChecked && region.subItems.length) {
            region.subItems.forEach((subItem) => {
                if (subItem.isChecked) flattened.push(subItem);
            });
        }
    });

    flattenedFilters.value = flattened;

    emit('onFilterChanged', selectedFilters.value);
}, { deep: true });
</script>

<style lang="scss" scoped>
$max-sub-chip-count: 10;

.search-filters {
    width: 100%;

    &.has-search {
        width: auto;
    }
}

.search-filters-wrapper {
    @include media-query(phone) {
        position: fixed;
        display: block;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: color(blanc);
        @include z-index(modal);
        padding: 2.35rem;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
}

.filters {
    display: flex;
    width: 100%;
    z-index: $max-sub-chip-count + 1;
    position: relative;

    @include media-query(phone) {
        flex-direction: column;
    }
}

.filter {
    position: relative;
    flex: 0 0 auto;
    border-inline-end: 1px solid color(noir, default, 0.08);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-inline-end: 1.6rem;

    &:not(:first-child) {
        padding-inline-start: 1.6rem;

        @include media-query(phone) {
            padding-inline-start: 0;
            border-top: 1px solid palette(border);
        }
    }
    &:last-child {
        border-inline-end: 0;
    }

    @include media-query(phone) {
        padding: 0;
        border-inline-end: none;
    }
}

.filter-chip-row {
    display: flex;
    justify-content: space-between;

    @include media-query(phone) {
        display: block;
        width: calc(100vw - 2 * var(--col-gutter));
        overflow-x: scroll;
    }
}

.chips-clear {
    @include hide-on(phone);
}

.filter-chips {
    padding-top: 0.8rem;
    padding-bottom: 1.6rem;
    display: flex;
    gap: 1rem;
}

.chip-and-sub {
    display: flex;
    align-items: center;
    margin-inline-end: 0.4rem;

    .chip {
        z-index: $max-sub-chip-count;
        height: 100%;
    }

    @for $i from 0 through $max-sub-chip-count - 1 {
        .subItem-#{$i} {
            z-index: #{($max-sub-chip-count - 1) - $i};
        }
    }
}

.chip {
    align-items: center;

    &:hover {
        text-decoration: line-through;
        .chip-icon {
            fill: color(blue, dark, 1);
        }
    }
}

.top-level:hover {
    background-color: color(blanc) !important;
}

.chip-icon {
    margin-inline-start: 0.8rem;
    display: inline-block;
    align-self: center;
    fill: color(blue, dark, 0.4);
    position: relative;
    top: 0.2rem;
    @include transition(fill, controls);

    svg {
        width: 0.55rem;
        height: 0.55rem;
    }
}

.breadcrumb-pill {
    @include font(mono, regular, 1.2rem, 1.3, false);
    letter-spacing: -0.01em;
    display: inline-block;
    background: #f8f8f8;
    border: 1px solid #d0d0d0;
    --pad-vert: 1.4rem;
    --pad-horiz: 2.4rem;
    --font-size: 1.2rem;
    --line-height: 1.3;
    --pill-height: calc(2 * var(--pad-vert) + var(--font-size) * var(--line-height));
    font-size: var(--fontsize);
    padding: var(--pad-vert) var(--pad-horiz);
    font-size: var(--font-size);
    line-height: var(--line-height);
    border-radius: 100rem;
    position: relative;
    z-index: -1;
    margin-inline-start: calc(-1 * var(--pill-height) - var(--pad-horiz));
    padding-inline-start: calc(var(--pad-horiz) + var(--pill-height) + 0.8rem);
}

.filter-label {
    color: color(blue, dark);
    opacity: 0.7;
    display: inline-flex;
    align-items: center;
    gap: 0.9rem;
}

.filter-count {
    opacity: 0.4;
}

.mobile-filter-heading {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @include show-on(phone, flex);
}

.mobile-filters-sticky {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.mobile-close-button {
    padding: 1.4rem;
    border: 0.15rem solid color(blue, dark, 0.4);
    border-radius: 0.5rem;
}

.mobile-close-icon {
    fill: color(blue);
}

.chips-controls-wrapper {
    @include media-query(phone) {
        @include content-section-padding;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0px -0.4rem 1rem 0px #0000001a;
        background-color: color(blanc);
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
}

.mobile-filter-controls {
    display: flex;
    justify-content: space-between;
    @include hide-on(not-phone);
}

.mobile-filters-toggle {
    padding: 1.6rem;
    border: 0.15rem solid color(blue, dark, 0.4);
    border-radius: 0.5rem;
    position: relative;
}

.mobile-filters-count {
    position: absolute;
    @include square(1.4rem);
    border-radius: 50%;
    background: color(teal);
    position: absolute;
    inset-inline-end: -0.7rem;
    top: -0.7rem;
    color: color(blanc);
    font-size: 0.8rem;
    text-align: center;
    line-height: 1.4rem;
}

.mobile-filter-toggler-title {
    display: flex;
    align-items: center;
}
</style>