import { z } from "zod";

const personIndexSchema = z.object({
    id: z.string(),
    status: z.string().nullable(),
    title: z.string(),
    lastName: z.string().nullable(),
})

export type PersonIndex = z.infer<typeof personIndexSchema>;

export const schema = personIndexSchema;

export const parse = (data: any): PersonIndex => schema.parse(data)
