<template>
  <div class="name-filter">
    <Typography
      v-if="allowClear"
      as="button"
      variant="body"
      :class="{ active: selectedLetter === '' }"
      @click="selectLetter('')"
      class="clear-filter"
    >
      All
    </Typography>
    <Typography 
      v-for="letter in alphabet" 
      :key="letter" 
      as="button" 
      variant="body"
      :class="{ active: selectedLetter === letter }"
      @click="selectLetter(letter)"
    >
      {{ letter }}
    </Typography>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

const props = defineProps({
  allowClear: {
    type: Boolean,
    default: true
  },
  initialLetter: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:letter']);

const alphabet = computed(() => {
  return Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
});

const selectedLetter = ref(props.initialLetter);

function selectLetter(letter: string) {
  selectedLetter.value = letter;
  emit('update:letter', letter);
}

// Expose the current selected letter for parent components
defineExpose({
  selectedLetter
});
</script>

<style lang="scss" scoped>
.name-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
  color: color(blue, dark);
  opacity: 0.7;
  
  button {
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    min-width: 28px;
    height: 3.2rem;
    text-align: center;
    transition: all 0.2s ease;

    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      border-color: color(teal, dark);
    }
    
    &.active {
      font-weight: bold;
      border-color: color(noir, dark);
      color: color(blue, dark);
      font-size: 2.4rem;
    }
  }
  
  .clear-filter {
    margin-left: 0.5rem;
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .name-filter {
    gap: 0.25rem;
    
    button {
      padding: 0.15rem 0.35rem;
      min-width: 24px;
    }
  }
}
</style>
