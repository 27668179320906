<template>
    <DropdownMenu v-if="items?.length" :isInFlowOnPhone="true" class="index-filter-menu">
        <template #label>
            <Typography variant="body" transform="capitalize" class="filter-label">
                {{ title }}
                <span class="filter-count">({{ items?.length || 0 }})</span>
            </Typography>
        </template>

        <template #dropdown>
            <ul class="menu-items">
                <li v-for="item in sortedItems" :key="item.id || item.title" class="menu-item">
                    <div class="menu-content">
                        <Typography variant="body" class="menu-label">
                            <Checkbox v-model:isChecked="item.isChecked" :ariaLabel="item.title">
                                {{
                                    item.title === 'Posts'
                                        ? $t('Commentary', 1, { locale: pageLanguage })
                                        : item.title
                                }}
                            </Checkbox>
                            <Icon
                                v-if="item.subItems?.length"
                                @click="openSubMenu(item)"
                                name="arrow-down"
                                :class="(item?.isChecked || item?.subMenuOpen) && 'is-flipped'"
                                class="arrow"
                            />
                        </Typography>
                    </div>

                    <ul v-if="(item?.isChecked || item?.subMenuOpen) && !!item?.subItems?.length" class="submenu">
                        <li v-for="subItem in item.subItems" :key="subItem.id || subItem.title" class="menu-item">
                            <div class="menu-content">
                                <Typography variant="body" class="menu-label">
                                    <Checkbox v-model:isChecked="subItem.isChecked" :ariaLabel="subItem.title">
                                        {{ subItem.title }}
                                    </Checkbox>
                                </Typography>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </template>
    </DropdownMenu>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: 'Filter',
    },
    filterKey: {
        type: String,
        default: '',
    },
    items: {
        type: Array,
        default: () => [],
    },
    isSubmenu: {
        type: Boolean,
        default: false,
    },
    pageLanguage: {
        type: String,
        default: 'en',
    },
});

const sortedItems = computed(() => {
    if (props.title === 'experts') return props.items.toSorted((a, b) => a?.lastName.localeCompare(b?.lastName));
    else if (props.title === 'years') {
        return props.items;
    } else return props.items.toSorted((a, b) => a?.title.localeCompare(b?.title));
});
const openSubMenu = (item) => {
    if (item?.subMenuOpen && item.subMenuOpen) {
        item.subMenuOpen = false;
    } else if (item?.subMenuOpen && !item.subMenuOpen) {
        item.subMenuOpen = true;
    } else {
        item.subMenuOpen = true;
    }
};
</script>

<style lang="scss" scoped>
.submenu {
    padding-inline-start: 2rem;
}

.menu-content {
    padding: 1.3em 0.9em 1.2em;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid palette(border);

    @include media-query(not-phone) {
        .menu-item:first-child & {
            border-top: none;
        }
    }
}

.menu-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.menu-items {
    max-height: 58.5rem;
    /* calculated to be 10 items */
    overflow-y: scroll;

    @include media-query(phone) {
        padding-inline-start: 2rem;
    }
}

.filter-label {
    color: color(blue, dark, 0.66);
}

.filter-count {
    color: color(blue, dark, 0.4);
}

.arrow {
    fill: none;
    stroke: color(blue, dark);
    opacity: 0.6;

    &.is-flipped {
        transform: rotate(180deg);
    }
}
</style>
