import { z } from "zod";

const topicIndexSchema = z.object({
    id: z.string(),
    status: z.string().nullable(),
    title: z.string(),
    centers: z.array(z.string()).nullable(),
    url: z.string().nullable(),
    hideFromTopicLists: z.array(z.string()).nullable(),
})

export type TopicIndex = z.infer<typeof topicIndexSchema>;

export const schema = topicIndexSchema;

export const parse = (data: any): TopicIndex => schema.parse(data)
