import { z } from "zod";

const regionIndexSchema = z.object({
    id: z.string(),
    status: z.string().nullable(),
    title: z.string(),
    centers: z.array(z.string()).nullable(),
    url: z.string().nullable(),
    type: z.string().nullable(),
    regionIds: z.array(z.string()).nullable(),
})

export type RegionIndex = z.infer<typeof regionIndexSchema>;

export const schema = regionIndexSchema;

export const parse = (data: any): RegionIndex => schema.parse(data)
